import React from "react"

import Layout from "../layouts/Layout/Layout"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"
import TwoColumnSection from "../sections/TwoColumnSection/TwoColumnSection"
import ThreeColSection from "../sections/ThreeColSection/ThreeColSection"
import FeaturesList from "../sections/FeaturesList/FeaturesList"
import TwoColFeatures from "../sections/TwoColFeatures/TwoColFeatures"

import Background from "../assets/images/hero_academy.jpg"

import IconMentoring from "../assets/svg/icon_mentoring.svg"
import IconSkills from "../assets/svg/icon_skills.svg"
import IconWorkshops from "../assets/svg/icon_workshops.svg"
import IconChart from "../assets/svg/icon_chart.svg"
import IconSciWallet from "../assets/svg/icon_wallet.svg"
import IconID from "../assets/svg/icon_identifiacationCard.svg"
import IconCompetencies from "../assets/svg/icon_competencies.svg"
import IconKnowledge from "../assets/svg/icon_knowledge.svg"
import IconHands from "../assets/svg/icon_hands.svg"
import IconHandshake from "../assets/svg/icon_handshake.svg"
import IconStudent from "../assets/svg/icon_student.svg"

const AcademyPage = () => {
  return (
    <Layout>
      <HeroSubpage title="Akademia" backgroundImage={Background} />
      <TwoColumnSection
        heading="3 kroki na drodze do wymarzonej kariery"
        article={
          "<h4>Akademia Alkantara to program angażujących zajęć i warsztatów biznesowych prowadzonych przez praktyków: doświadczonych przedsiębiorców, managerów zarządzających zespołami oraz świetnych szkoleniowców.</h4>" +
          "<p>Skuteczny rozwój kompetencji biznesowych młodych ludzi i zapewnienie im lepszego startu na rynku pracy to najważniejszy cel Akademii Alkantara. By go zrealizować, łączymy <strong>trzy obszary działania:</strong></p>" +
          "<ol>" +
          "<li><strong>edukację</strong></li>" +
          "<li><strong>mentoring</strong></li>" +
          "<li><strong>praktykę zawodową</strong></li>" +
          "</ol>" +
          "<p>Każdy z uczestników otrzymuje kompleksowe wsparcie dedykowanego mentora oraz możliwość skorzystania z płatnej praktyki zawodowej w wybranej partnerskiej firmie.</p>"
        }
      />
      <ThreeColSection
        id={"edukacja"}
        heading="I. Edukacja"
        headingColor="#9A6846"
        article1={"<h3>Uczymy funkcjonowania na rynku pracy</h3>"}
        article2={
          "<p><strong>Nasze zajęcia i warsztaty</strong> prowadzą eksperci odnoszący sukcesy na polu biznesowym. To właściciele firm, managerowie zarządzający projektami i zespołami, a także szkoleniowcy specjalizujący się w umiejętnościach biznesowych.</p>" +
          "<p><strong>Naszym celem</strong> jest kompleksowe przygotowanie uczestników Akademii Alkantara do funkcjonowania na rynku pracy zarówno jako pracownik, jak i osoba zarządzająca zespołem czy właściciel firmy. </p>"
        }
      />
      <TwoColFeatures
        heading="Ofertę programową Akademii Alkantara tworzymy w oparciu o następujące obszary tematyczne:"
        features={[
          {
            color: "#9A6846",
            icon: "",
            heading: "Przedsiębiorczość i finanse",
          },
          {
            color: "#9A6846",
            icon: "",
            heading: "Organizacja projektowa i zarządzanie przez projekty",
          },
          {
            color: "#9A6846",
            icon: "",
            heading:
              "Kompetencje miękkie: komunikacja, współpraca i techniki negocjacji",
          },
          {
            color: "#9A6846",
            icon: "",
            heading:
              "Kultura: elementy kulturoznawstwa, filozofii i podstawy sztuki",
          },
        ]}
      />
      <FeaturesList
        items={[
          {
            backgroundColor: "#9A6846",
            svg: IconWorkshops,
            text: "Zajęcia i warsztaty z praktykami",
          },
          {
            backgroundColor: "#9A6846",
            svg: IconKnowledge,
            text: "Dostęp do eksperckiej wiedzy biznesowej",
          },
          {
            backgroundColor: "#9A6846",
            svg: IconCompetencies,
            text: "Nauka cennych kompetencji zawodowych",
          },
          {
            backgroundColor: "#9A6846",
            svg: IconID,
            text: "Mocna pozycja startowa na rynku pracy",
          },
        ]}
      />
      <TwoColumnSection
        id="mentoring"
        heading="II. Mentoring"
        headingColor="#41431B"
        article={
          "<h4>Dajemy przykład i wspieramy na drodze do kariery</h4>" +
          "<p>Każdy z uczestników Akademii Alkantara otrzymuje wsparcie dedykowanego mentora z obszaru biznesu.</p>" +
          "<p><strong>Kim są mentorzy Akademii Alkantara?</strong></p>" +
          "<p>To starannie wybrani pomorscy przedsiębiorcy, managerowie i szkoleniowcy o ugruntowanej pozycji. Ich kompetencje koncentrują się wokół:</p>" +
          "<ul>" +
          "<li>prowadzenia działalności biznesowej</li>" +
          "<li>skutecznego zarządzania projektami i zespołami</li>" +
          "<li>wykorzystania umiejętności biznesowych w praktyce</li>" +
          "<li>budowania dobrych relacji zawodowych</li>" +
          "</ul>" +
          "<p>Każdy z naszych mentorów jest otwarty na pytania uczestników i gotowy udzielać niezbędnych rad w obszarze edukacji i rozwoju zawodowego. Dzięki wsparciu mentorów, uczestnicy Akademii Alkantara mogą w prosty sposób zobaczyć, jak wygląda praca specjalistów i jak firmy funkcjonują od podszewki.</p>"
        }
      />
      <FeaturesList
        colNumber={3}
        items={[
          {
            backgroundColor: "#41431B",
            svg: IconMentoring,
            text: "Długofalowa współpraca z mentorem",
          },
          {
            backgroundColor: "#41431B",
            svg: IconKnowledge,
            text: "Dostęp do unikalnej wiedzy i doświadczenia biznesowego",
          },
          {
            backgroundColor: "#41431B",
            svg: IconChart,
            text: "Poznanie mechanizmów działania biznesu",
          },
          {
            backgroundColor: "#41431B",
            svg: IconHands,
            text: "Wsparcie w rozwiązywaniu problemów",
          },
          {
            backgroundColor: "#41431B",
            svg: IconSkills,
            text: "Nauka niezbędnych kompetencji biznesowych",
          },
          {
            backgroundColor: "#41431B",
            svg: IconID,
            text: "Mocna pozycja startowa na rynku pracy",
          },
        ]}
      />
      <ThreeColSection
        id="praktykaZawodowa"
        heading="III. Praktyka zawodowa"
        headingColor="#465E9A"
        article1={
          "<h3>Wejdź wcześniej na rynek pracy i rozkręć karierę</h3>" +
          "<p>Wiedza i wsparcie doświadczonego mentora świetnie sprawdzą się w praktyce zawodowej, która jest trzecim elementem Akademii Alkantara. Zależy nam na kompleksowym podejściu do rozwoju zawodowego, dlatego dajemy naszym uczestnikom możliwość podjęcia płatnej praktyki zawodowej w wybranej firmie partnerskiej.</p>"
        }
        article2={
          "<h3>Jak to działa?</h3>" +
          "<p>Praktyka zawodowa będzie elementem praktyki zawodowej przeprowadzonej u przedsiębiorców, którzy są partnerami Programu Alkantara. Twoja praktyka będzie świetną okazją, by wykorzystać wiedzę i umiejętności zdobyte na studiach kierunkowych i w Akademii Alkantara. Kiedy z niego skorzystasz? We wspólnie ustalonym terminie, na przykład w czasie przerwy wakacyjnej lub w późniejszym okresie studiów, gdy praca nie będzie kolidowała z zajęciami na uczelni. Co ważne, Twoja praktyka będzie płatna – za swoją pracę otrzymasz wynagrodzenie w wysokości odpowiadającej wynagrodzeniu pracowników o Twoich kompetencjach. To świetny początek zawodowej przygody.</p>"
        }
      />
      <FeaturesList
        noBorder
        items={[
          {
            backgroundColor: "#465E9A",
            svg: IconStudent,
            text: "Praktyka zawodowa zgodna z Twoim kierunkiem studiów",
          },
          {
            backgroundColor: "#465E9A",
            svg: IconSciWallet,
            text: "Wynagrodzenie adekwatne do zdobytych kompetencji",
          },
          {
            backgroundColor: "#465E9A",
            svg: IconHandshake,
            text: "Współpraca z jedną z firm partnerskich Programu Alkantara",
          },
          {
            backgroundColor: "#465E9A",
            svg: IconID,
            text: "Mocny start na rynku pracy",
          },
        ]}
      />
    </Layout>
  )
}

export default AcademyPage
