import React from "react"
import styled from "styled-components"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import { fonts } from "../../utils/fonts"
import { color } from "../../utils/colors"
import { rgba } from "polished"


const StyledTitleLarge = styled.h2`
  ${fonts.headingMedium};
  width: calc(100% / ${({ equalSections }) => (equalSections ? 2 : 3)});
  flex-shrink: 0;
  padding-right: 40px;
  border-right: solid 1px ${color.separatorGrey};
  @media screen and (max-width: 1100px) {
    border-right: 0;
    width: 100%;
    margin-bottom: 40px;
  }
`

const StyledColumnWrapper = styled.ul`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  @media screen and (max-width: 1100px) {
    padding-left: 0;
  }
`

const StyledRowWrapper = styled.div`
  display: flex;
  padding-bottom: 80px;
  padding-top: 80px;
  border-bottom: solid 1px
    ${({ noBorder }) => (noBorder ? "transparent" : color.separatorGrey)};
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const StyledItemWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 40px;
  }
`

const StyledIconWrapper = styled.div`
  height: 96px;
  width: 96px;
  border-radius: 50%;
  background-color: ${({ color }) => rgba(color, 0.1)};
  margin-right: 40px;
  flex-shrink: 0;
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: 700;
`

const StyledHeading = styled.h5`
  ${fonts.headingSmall}
`

const TwoColFeatures = ({ heading, noBorder, features }) => (
  <LayoutWrapper>
    <StyledRowWrapper noBorder={noBorder}>
      <StyledTitleLarge>{heading}</StyledTitleLarge>
      <StyledColumnWrapper>
        {features.map(({ color, icon, heading }, index) => {
          return (
            <StyledItemWrapper>
              <StyledIconWrapper color={color}>{index + 1}</StyledIconWrapper>
              <StyledHeading>{heading}</StyledHeading>
            </StyledItemWrapper>
          )
        })}
      </StyledColumnWrapper>
    </StyledRowWrapper>
  </LayoutWrapper>
)

export default TwoColFeatures
